import type { NextPage } from "next"

import { ProductList } from "@app/components"
import { fetchProducts, Product } from "@app/inventory"
import { info } from "@app/logger"

const Home: NextPage<{ products: Product[] }> = ({ products }) => {
  return <ProductList products={products} />
}

export async function getStaticProps() {
  info(`Fetching products`)

  const products = await fetchProducts()
  return {
    props: {
      products,
    },
    revalidate: 180, // In seconds
  }
}

export default Home
